/* eslint-disable */
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

import { Download, StageImageless } from '../components/molecules';
import { DefaultLayout } from '../components/layout';

const pageQuery = graphql`
  {
    allDownloadsJson {
      edges {
        node {
          title
          catchline
          lead
          body
          image {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 800, quality: 92) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          linkedDownload {
            url
            title
          }
        }
      }
    }
    allStagesJson(filter: { siteTitle: { eq: "Downloads" } }) {
      edges {
        node {
          id
          siteTitle
          siteDescription
          title
          contentBlocks {
            id
            value
          }
          imageSrc {
            childImageSharp {
              fluid(maxWidth: 800, quality: 92) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          imageAlt
        }
      }
    }
  }
`;

const Downloads = ( { location }) => (
  <StaticQuery
    query={pageQuery}
    render={({ allStagesJson, allDownloadsJson }) => {
      const { siteTitle, siteDescription, imageSrc, imageAlt, title, contentBlocks } = allStagesJson.edges[0].node;

      return (
        <DefaultLayout siteTitle={siteTitle} siteDescription={siteDescription} location={location}>
          <StageImageless
            modifiers={['left-highlighted', 'gradient']}
            image={{
              fluid: imageSrc.childImageSharp.fluid,
              alt: imageAlt,
            }}
            title={<h1 dangerouslySetInnerHTML={{ __html: title }} />}
          >
            <div>
              {contentBlocks.map(({ id, value }) => (
                <p key={id}>{value}</p>
              ))}
            </div>
          </StageImageless>
          <div className="container">
            <div className="row">
              {allDownloadsJson.edges.map(({ node }) => {
                const { title: downloadTitle, catchline, lead, image, linkedDownload, body } = node;
                const { extension, publicURL, childImageSharp } = image;

                return (
                  <Download
                    key={downloadTitle}
                    title={downloadTitle}
                    catchline={catchline}
                    lead={lead}
                    image={{
                      ...(extension === 'svg' ? { src: publicURL } : { fluid: childImageSharp.fluid }),
                      alt: downloadTitle,
                    }}
                    linkedDownload={linkedDownload || null}
                  >
                    <div dangerouslySetInnerHTML={{ __html: body }} />
                  </Download>
                );
              })}
            </div>
          </div>
        </DefaultLayout>
      );
    }}
  />
);

export default Downloads;
